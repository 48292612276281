import * as React from 'react';

import LayoutDefault from '../layouts/default';

import Image404 from '../sbAssets/assets/img/illustrations/404-error-pana.svg';

const NotFoundPage = () => {
  return (
    <>
      <LayoutDefault>
        <section className="bg-white py-10">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-6">
                <div className="text-center mt-4">
                  <img className="img-fluid p-4" src={Image404} alt="..." />
                  <p className="lead">
                    This requested URL was not found on this server.
                  </p>
                  <a className="text-arrow-icon" href="/">
                    <i className="ms-0 me-1" data-feather="arrow-left" />
                    Return Home
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-black">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default NotFoundPage;
